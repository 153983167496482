@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

// TESTMONIALS SECTION
.tc-compines-success {
    position: relative;
    padding: 100px 0;
    background-color: rgba($white, 0.01);
    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }
    &::before {
        content: "";
        background: linear-gradient(to left, rgba($white, 0), rgba($white, 0.03) 10%, rgba($white, 0));
        height: 2px;
        position: absolute;
        top: 0;
        width: 100%;
        background-size: 400%;
        animation: bg_animation 4s infinite alternate;
    }
    &::after {
        content: "";
        background: linear-gradient(to right, rgba($white, 0), rgba($white, 0.03) 10%, rgba($white, 0));
        height: 2px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-size: 400%;
        animation: bg_animation 4s infinite alternate;
    }
    .componies-swiper {
        padding: 60px 0 0 0;
        pointer-events: none;
        position: relative;
        .item_img {
            img {
                filter: grayscale(1);
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
    }
}


.fv-download-file {
    padding-top: var(--space-section);
    .img_illustration {
        img {
            width: 100%;
            height: 172px;
            object-fit: contain;
        }
    }
    .display-text-file {
        h4 {
            font-size: $size-20;
            font-weight: $medium;
            color: var(--dark);
            margin-bottom: 8px;
        }
        p {
            font-size: $size-16;
            color: var(--gray-600);
            margin-bottom: 16px;
        }
        .btn-download {
            background-color: var(--primary);
            border: 0;
            color: var(--white);
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                display: flex;
                align-items: center;
                gap: 6px;
                position: absolute;
                opacity: 0;
                font-size: 20px;
                color: var(--white);
                transform: translateY(-16px);
                transition: .4s cubic-bezier(0.77,0,0.18,1);
                span {
                    font-size: $size-16;
                }
            }
            p {
                transition: .4s cubic-bezier(0.77,0,0.18,1);
                margin: 0;
                color: var(--white);
                font-weight: $normal;
            }
            &:hover {
                p {
                    transform: translateY(16px);
                    opacity: 0;
                }
                .icon {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}
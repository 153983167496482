@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fv-contact-section {
    padding: var(--space-section) 0;
    .display-title-section {
        margin-bottom: 50px;
        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
    }
    .wrapper-form-input {
        .btn-submit {
            background-color: var(--white);
            color: var(--dark);
            position: absolute;
            left: 24px;
            bottom: 24px;
        }
    }
    .wrapper-info-cc {
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-wrap: wrap;
            column-gap: 30px;
            .text-grid {
                margin-bottom: 20px;
            }
        }
    }
    .text-grid {
        margin-bottom: 32px;
    }
}

.text-grid {
    span {
        display: block;
        font-size: $size-16;
        color: var(--gray-700);
        margin-bottom: 8px;
    }
    p,
    a {
        font-size: $size-16;
        margin: 0;
        color: var(--white);
        font-weight: $medium;
        text-decoration: none;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.main_intro {
    position: relative;
    .cover_main {
        position: relative;
        .main_imh {
            width: 100%;
            height: 750px;
            object-fit: cover;
        }
        .arrow-right {
            position: absolute;
            right: -9px;
            top: -37px;
            transform: rotate(-45deg);
        }
        &::after {
            content: "";
            @include overlay;
            background: linear-gradient(to top, rgba($dark, 0.8), rgba($dark, 0));
        }
    }
    .wrapper_content {
        padding: 40px 120px 40px 40px;
        background-color: var(--primary);
        clip-path: polygon(16% 0, 100% 0%, 100% 100%, 0% 100%);
        position: absolute;
        right: -50px;
        width: 56%;
        bottom: 80px;
        z-index: 1;
        
        @include media-breakpoint-down(lg) {
            width: 86%;
            bottom: 180px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            bottom: 180px;
            right: 0;
            clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
            padding: 40px 20px;
        }
        .display-text-head {
            h1 {
                color: #242015;
            }
            .text {
                width: 70%;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                p {
                    color: #403925;
                    margin: 0;
                }
                .btn-more {
                    position: relative;
                    padding: 0;
                    color: var(--dark);
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border: 0;
                    @include transition-all;
                    margin: 24px 0 0 0;
                    i {
                        width: 20px;
                        height: 20px;
                        background-color: rgba($dark, 0.15);
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: $radius-circle;
                    }
                }
            }
        }
    }
    .shape_clip {
        position: absolute;
        clip-path: polygon(12% 0, 100% 0%, 100% 100%, 0% 100%);
        background: linear-gradient(to bottom, rgba(#786b45, 1), rgba(#786b45, 0));
        width: 51%;
        height: 170px;
        top: 54%;
        right: 0;
        z-index: 0;
        
        @include media-breakpoint-down(lg) {
            width: 78%;
            top: 35%;
        }
        @include media-breakpoint-down(md) {
            width: 88%;
            top: 40%;
            clip-path: polygon(0% 0, 100% 0%, 100% 100%, 0% 100%);
        }
    }
    .social-head-intro {
        position: absolute;
        left: 0;
        top: -130px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        @include media-breakpoint-up(md) {
            column-gap: 75px;
        }
        gap: 20px;
        @include media-breakpoint-down(md) {
            right: 20px;
        }
        .location {
            display: flex;
            align-items: center;
            gap: 16px;
            .icon {
                font-size: 24px;
                color: var(--white);
            }
            .text {
                span {
                    font-size: $size-14;
                    color: var(--gray-300);
                    display: block;
                    margin-bottom: 8px;
                }
                p {
                    margin: 0;
                    font-size: $size-14;
                    font-weight: $medium;
                }
            }
        }
        .socials {
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
            .btn {
                width: 38px;
                height: 38px;
                border-radius: $radius-circle;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: var(--white);
                padding: 0;
                border: 0;
                border: 1px solid transparent;
                @include transition-bg;
                i {
                    font-size: 16px;
                    color: var(--dark);
                    @include transition-color;
                }
                &:hover {
                    border: 1px solid rgba($white, 0.4);
                    background-color: transparent;
                    i {
                        color: var(--white);
                    }
                }
                &.btn-go {
                    background-color: transparent;
                    border: 1px solid var(--light);
                    i {
                        color: var(--white) !important;
                        font-size: 20px;
                    }
                    &:hover {
                        background-color: rgba($white, 0.25);
                        border-color: transparent;
                    }
                }
            }
        }
    }
}



@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fv-statistics-section {
    padding: var(--space-section) 0;
}

.wrapper-statistics {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
    .content_no {
        display: grid;
        gap: 60px;
        @include media-breakpoint-down(sm) {
            gap: 40px;
        }
    }
    .description {
        display: grid;
        gap: 90px;
        @include media-breakpoint-down(sm) {
            gap: 60px;
        }
    }
    .number_display {
        text-align: center;
        span {
            font-size: 48px;
            font-weight: $medium;
            display: block;
            margin-bottom: 2px;
            color: var(--primary);
            font-family: "Poppins", sans-serif;
            @include media-breakpoint-down(sm) {
                font-size: $size-24;
            }
        }
        p {
            color: var(--gray-300);
            margin: 0;
            font-size: 16px;
            font-weight: $medium;
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }
    }
    .description {
        p {
            font-size: $size-24;
            font-weight: $medium;
            color: var(--white);
            line-height: normal;
            margin: 0;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
        }
    }
    .outline-timeline {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        span {
            &:nth-child(odd) {
                background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='19' cy='19' r='18.5' stroke='%236B6C6D' stroke-linejoin='round' stroke-dasharray='3 3'/%3E%3Cpath d='M18.9999 21.5L15.4641 17.9642L16.6433 16.7858L18.9999 19.1433L21.3566 16.7858L22.5358 17.9642L18.9999 21.5Z' fill='white'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 38px;
                width: 38px;
                height: 38px;
                display: block;
                @include media-breakpoint-down(sm) {
                    background-size: 28px;
                    width: 28px;
                    height: 28px;
                }
            }
            &:nth-child(even) {
                background-image: url("data:image/svg+xml,%3Csvg width='1' height='88' viewBox='0 0 1 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.5' y1='-2.18557e-08' x2='0.500004' y2='88' stroke='url(%23paint0_linear_40_245)' stroke-dasharray='3 3'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_40_245' x1='-2.00998e-09' y1='-4.04598' x2='-1.13364e-07' y2='92.5517' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%236B6C6D' stop-opacity='0'/%3E%3Cstop offset='0.513542' stop-color='%236B6C6D'/%3E%3Cstop offset='1' stop-color='%236B6C6D' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: auto;
                height: 88px;
                width: 2px;
                display: block;
                @include media-breakpoint-down(sm) {
                    height: 38px;
                    background-size: 30%;
                    width: 4px;
                }
            }
            &:last-child {
                background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='19' cy='19' r='18.5' stroke='%236B6C6D' stroke-linejoin='round' stroke-dasharray='3 3'/%3E%3Ccircle cx='19' cy='19' r='4' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

.statistics-section-numbers {
    background-color: var(--white);
    margin-top: var(--space-section);
    margin-bottom: 10px;
    .item {
        padding: 40px 20px;
        border-left: 1px solid var(--gray-200);
        text-align: center;
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid var(--gray-200);
        }
        span {
            font-size: 48px;
            color: var(--dark);
            line-height: normal;
            font-weight: $semiBold;
            display: block;
            margin-bottom: 4px;
            font-family: "Poppins", sans-serif;
        }
        p {
            font-size: $size-16;
            color: var(--dark);
            margin: 0;
        }
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.liveToastMsg {
    width: 300px;
    position: fixed;
    z-index: 10;
    bottom: 40px;
    border: 0;
    box-shadow: none;
    border-radius: 8px;
    animation: slide-out-toastmsg 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
    background-color: var(--dark);
    &.success {
        background-color: $green;
        .icon {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.2' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.6677 9.2323C16.9536 9.532 16.9424 10.0067 16.6427 10.2927L11.1396 15.5427C10.8496 15.8193 10.3934 15.8191 10.1037 15.5422L7.35684 12.9172C7.05738 12.631 7.0466 12.1563 7.33278 11.8568C7.61895 11.5574 8.0937 11.5466 8.39317 11.8328L10.6223 13.963L15.6073 9.20734C15.907 8.92142 16.3817 8.93259 16.6677 9.2323Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z' fill='white'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: 20px;
            height: 20px;
            width: 20px;
        }
    }
    &.failed {
        background-color: $red;
        .icon {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23ffffff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Ccircle cx='128' cy='128' r='96' opacity='0.2'%3E%3C/circle%3E%3Ccircle cx='128' cy='128' r='96' fill='none' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='16'%3E%3C/circle%3E%3Cline x1='128' y1='80' x2='128' y2='136' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/line%3E%3Ccircle cx='128' cy='172' r='12'%3E%3C/circle%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 20px;
            height: 20px;
            width: 20px;
        }
    }
    .toast-body {
        color: $white;
        width: 100%;
        padding: 14px 16px;
        display: flex;
        align-items: center;
        .text {
            margin-right: 8px;
        }
        h4 {
            font-size: $size-14;
            font-weight: $medium;
            margin: 0 0 2px 0;
        }
        p {
            margin: 0;
            font-size: $size-15;
            font-weight: $medium;
        }
    }
    &:not(.show) {
        display: block;
        opacity: 0;
    }
    &.fade:not(.show) {
        opacity: 1;
    }
    .btn-close {
        background: none;
        opacity: 1;
        display: inline-flex;
        align-items: center;
        width: 30px;
        padding: 0 10px 0 5px;
        color: $white !important;
        filter: none;
        i {
            font-size: 18px;
            color: $white !important;
        }
    }
    &.showing {
        opacity: 1;
    }
    &.show {
        animation: slide-in-toastmsg 0.8s cubic-bezier(0.77, 0, 0.18, 1) both;
    }
}
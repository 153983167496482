
// MY THEME FONTS SIZE 
$size-11: 0.688rem;
$size-12: 0.75rem;
$size-13: 0.813rem;
$size-14: 0.875rem;
$size-15: 0.938rem;
$size-16: 1rem;
$size-17: 1.063rem;
$size-18: 1.125rem;
$size-19: 1.188rem;
$size-20: 1.25rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-34: 1.938rem;
$size-60: 3.75rem;

// MY THEME FONTS WEIGHT 

$normal: 400;
$medium: 500;
$semiBold: 600;
$bold:   700;

// BORDER RADIUS

$radius-0:       0;
$radius-rounded: 100px;
$radius-circle: 50%;

//BORDER

$border:  1px;
$border-2: 2px;


// SPACE
$space-section: 130px;

// MY THEME COLORS

$brouwn: #B8A46B;
$blue:   #6ba2ff;
$purple: #5f409a;
$pink:   #952c7f;
$red:    #fb3f3f;
$orange: #ea494e;
$yellow: #ea9949;
$green:  #40d8aa;


// WHITE AND GRAY COLORS

$white:    #fff;
$gray-100: #F9F9F9;
$gray-200: #EDEDEE;
$gray-300: #D2D3D3;
$gray-400: #B2B3B4;
$gray-500: #9FA0A1;
$gray-600: #88898A;
$gray-700: #6B6C6D;
$gray-800: #3E3F41;
$gray-900: #101112;
$black:    #000;

//MY THEME COLORS VARIABLES

$primary:   $brouwn;
$light:     $gray-100;
$dark:      $gray-900;

// ROOT
:root {
  --primary: #{$primary};
  --light: #{$light};
  --dark: #{$dark};
  --white: #{$white};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --black: #{$black};
  --space-section: #{$space-section};
  --size-34: #{$size-34};
  --size-60: #{$size-60};
}


//CUSTOM GRID BREAKPOINTS => 1400 XXL 1550
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1550px
);


// CUSTOME CONTAINER
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// IMPORT BOOTSTRAP-V5
 @import "../scss/bootstrap/bootstrap.scss";
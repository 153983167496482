@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.form-floating,
.input-group {
    position: relative;
    margin-bottom: 10px;
    > {
        label {
            left: inherit;
            right: 0;
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $normal;
            padding: 24px;
            transition: 0.5s transform cubic-bezier(0.86, 0, 0.07, 1) !important;
            transform-origin: right;
            opacity: 1 !important;
        }
    }
    .form-control {
        height: 80px !important;
        border-radius: 0;
        box-shadow: inset 0 0 1px 1px rgba($white, 0.3) !important;
        border: 0;
        background-color: rgba($white, 0);
        color: var(--white) !important;
        font-size: $size-18;
        font-weight: $medium;
        padding-left: 24px !important;
        padding-right: 24px !important;
        @include transition-all;
        &:not(:placeholder-shown) {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.12) !important;
            background-color: rgba($white, 0.02);
            ~ {
                label {
                    transform: scale(0.7) translateY(-25px) translateX(-8px) !important;
                }
            }
        }
        &::placeholder {
            font-size: $size-18;
            color: var(--gray-600);
            font-weight: $medium;
            opacity: 0;
        }
        &:hover {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
        }
        &:focus {
            box-shadow: inset 0 0 1px 1px rgba($white, 0.8) !important;
            background-color: rgba($white, 0.02);
            ~ {
                label {
                    transform: scale(0.7) translateY(-25px) translateX(-8px) !important;
                }
                .icon {
                    transform: translateX(0);
                    opacity: 0.8;
                }
            }
        }
        &.is-invalid {
            background-image: none;
            box-shadow: inset 0 0 1px 1px $yellow !important;
        }
    }
    .invalid-feedback {
        color: $yellow;
        font-size: $size-14;
        margin-top: 12px;
    }
    .icon {
        background-size: 24px;
        width: 24px;
        height: 24px;
        position: absolute;
        left: 24px;
        top: 30px;
        opacity: 0;
        transform: translate(-14px);
        transition: 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    }
    textarea {
        min-height: 200px;
        resize: none;
        &.form-control {
            padding-top: 48px !important;
        }
    }
}
@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    @FILE: DEFAULT STYLE
    @AUTHER: HASAN SIBAKHI
    @URL: https://dribbble.com/HasanSib

    THIS FILE CONTAINS THE STYLING FOR THE ACTUAL TEMPLATE, THIS
    IS THE FILE YOU NEED TO EDIT TO CHANGE THE LOOK OF THE
    TEMPLATE.

*/
html,
body {
    direction: rtl;
}

::selection {
    background-color: var(--primary);
    color: $white;
}

body {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--dark);
    @include media-breakpoint-up(md) {
        padding: 0 50px;
    }
    font: {
        family: "IBM Plex Sans Arabic", sans-serif;
        size: $size-16;
        weight: $normal;
    }
    color: var(--white);
    &.modal-open {
        overflow-y: auto !important;
        padding-right: 0 !important;
        .tc-side_menu {
            padding-right: 0 !important;
        }
    }
}

button,
input,
textarea,
label,
a {
    outline: 0 !important;
    box-shadow: none !important;
}

img {
    user-select: none;
}

i {
    display: inline-block;
}

:root {
    --size-60: 34px;
    @include media-breakpoint-down(sm) {
        --space-section: 50px;
    }
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    border-left: 50px solid var(--dark);
    @include media-breakpoint-down(sm) {
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid var(--dark);
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}


.administrative-structure {
    background-color: var(--white);
    padding: var(--space-section) 50px;
    margin-bottom: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-top: 40px;
    }
}
@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fc-ceo-section {
    background-color: var(--white);
    margin: 305px 0 0 0;
    padding-bottom: var(--space-section);
    @include media-breakpoint-down(sm) {
        margin: 185px 0 0 0;
    }
    .fv-cover-ceo {
        position: relative;
        margin-top: -130px;
        img {
            width: 100%;
            height: 520px;
            object-fit: cover;
        }
        .arrow-right {
            bottom: 26px;
            right: -8px;
            position: absolute;
            transform: rotate(45deg);
            border-left: 50px solid var(--white);
        }
        .gradient_bg {
            background: linear-gradient(to bottom, rgba($white, 0.08), rgba($white, 0));
            width: 100%;
            height: 120px;
            z-index: -1;
            position: absolute;
            top: -20px;
            left: -18px;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .figure-caption {
            @include media-breakpoint-up(lg) {
                padding-right: 50px;
            }
            position: relative;
            margin-top: 20px;
            .name_ceo {
                font-size: $size-18;
                color: var(--dark);
                font-weight: $medium;
                margin-bottom: 8px;
            }
            span {
                font-size: $size-16;
                color: var(--gray-600);
                font-weight: $normal;
            }
        }
    }
    .wrapper-typo {
        position: relative;
        padding-bottom: var(--space-section);
        @include media-breakpoint-up(lg) {
            margin-top: -90px;
            padding-right: 40px;
        }
        .display-title-section {
            h2 {
                @include media-breakpoint-down(lg) {
                    color: var(--dark) !important;
                }
            }
        }
        .dislay-text {
            margin-top: 90px;
            @include media-breakpoint-down(lg) {
                margin-top: 20px;
            }
            p {
                font-size: $size-16;
                color: var(--gray-800);
            }
        }
    }
}

.fv-vision {
    padding-top: var(--space-section);
    .illustration {
        img {
            width: 100%;
            max-width: 144px;
            height: 110px;
            object-fit: contain;
        }
    }
    .display-title-section {
        p {
            color: var(--gray-800);
            margin-top: 32px;
            @include media-breakpoint-down(sm) {
                margin-top: 16px;
            }
        }
    }
    &.msg {
        .display-title-section {
            p {
                color: var(--gray-300);
                margin-top: 32px;
                @include media-breakpoint-down(sm) {
                    margin-top: 16px;
                }
            }
        } 
    }
}

.dividar {
    height: 1px;
    width: 100%;
    background-color: var(--gray-200);
}
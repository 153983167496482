@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    // IMPORT 'IBM Plex Sans Arabic', 'Poppins' FROM GOOGLE FONTS
    
    REGULAR   :   400
    MEDIUM    :   500
    SEMIBOLD  :   600
    BOLD      :   700

    // THESE ARE THE NAMES IF YOU ARE USING A DESIGN FIILE SUCH AS ADOBE XD.
*/
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap');


.display-text-head {
    h1 {
        font-size: var(--size-60);
        font-weight: $bold;
        margin: 0 0 12px 0;
        line-height: normal;
    }
    p {
        font-size: $size-16;
    }
}

.display-title-section {
    .shape {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.4 5.79999C11.5075 5.79999 12.5071 5.3384 13.217 4.59711C13.401 5.19896 13.5 5.83791 13.5 6.49999C13.5 10.0898 10.5899 13 7 13C3.41015 13 0.5 10.0898 0.5 6.49999C0.5 2.91237 3.40654 0.00360941 6.99331 0C6.67909 0.562196 6.5 1.21018 6.5 1.89999C6.5 4.05391 8.24609 5.79999 10.4 5.79999ZM4.8 9.59999C5.51797 9.59999 6.1 9.01796 6.1 8.29999C6.1 7.58202 5.51797 6.99999 4.8 6.99999C4.08203 6.99999 3.5 7.58202 3.5 8.29999C3.5 9.01796 4.08203 9.59999 4.8 9.59999Z' fill='%23B8A46B'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 13px;
        width: 13px;
        height: 13px;
        margin-bottom: 16px;
        display: inline-flex;
    }
    span {
        color: var(--primary);
        font-weight: $medium;
        font-size: $size-15;
        display: block;
        margin-bottom: 16px;
    }
    h2 {
        font-size: var(--size-34);
        font-weight: $bold;
        line-height: normal;
        margin: 0;
    }
    p {
        font-size: $size-16;
        font-weight: $normal;
        color: var(--gray-600);
        margin-top: 16px;
        margin-bottom: 0;
    }
    ul {
        padding: 0 20px;
        margin: 0;
        margin-top: 32px;
        li {
            font-size: $size-16;
            color: var(--gray-400);
            margin-bottom: 16px;
            font-weight: $normal;
        }
    }
}
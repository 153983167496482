@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fv-story-section {
    background-color: var(--white);
    padding: 80px 0;
    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }
    h2 {
        font-size: 39px;
        font-weight: $bold;
        color: var(--dark);
    }
    .fv-description {
        font-size: $size-20;
        color: var(--gray-800);
        margin-bottom: 32px;
    }
    .btn {
        border: 1px solid var(--gray-200);
        margin: 0;
        @include transition-bg;
        &:hover {
            background-color: var(--light);
        }
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.me-auto {
    margin-right: inherit !important;
}

.me-auto {
    margin-left: auto !important;
}

.ms-auto {
    margin-left: inherit !important;
}

.ms-auto {
    margin-right: auto !important;
}

.ms-lg-auto {
    @include media-breakpoint-up(lg) {
        margin-left: inherit !important;
    }
    
}

.ms-lg-auto {
    @include media-breakpoint-up(lg) {
        margin-right: auto !important;
    }
}

.row {
   @include media-breakpoint-down(sm) {
    --bs-gutter-x: 0;
   }
}
@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: var(--dark);
    .navbar {
        padding: 20px 0;
        @include media-breakpoint-down(sm) {
            padding: 10px 0;
        }
        .navbar-brand {
            margin: 0;
            img {
                width: 100%;
                max-width: 145px;
                height: 50px;
                object-fit: contain;
            }
        }
        .navbar-toggler {
            background-color: transparent;
            border: 0;
            color: var(--white);
        }
        .navbar-nav {
            @include media-breakpoint-up(lg) {
                gap: 30px;
            }
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
            .nav-item {
                .nav-link {
                    position: relative;
                    font-size: $size-14;
                    color: var(--white);
                    font-weight: $medium;
                    text-decoration: none;
                    display: inline-flex;
                    justify-content: center;
                    @include media-breakpoint-down(sm) {
                        border-bottom: 1px solid var(--gray-800);
                        width: 100%;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background-color: var(--primary);
                        display: flex;
                        bottom: -6px;
                        border-radius: $radius-circle;
                        opacity: 0;
                        @include transition-all;
                    }
                    &.active,
                    &[aria-current="page"] {
                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .block-left-nav {
            display: flex;
            align-items: center;
            gap: 40px;
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                gap: 0;

                .display-phone,
                .display-en-lang {
                    border-bottom: 1px solid var(--gray-800);
                    width: 100%;
                    padding: 10px 0;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .display-phone {
            font-family: "Poppins", sans-serif;
        }
        .display-phone,
        .display-en-lang {
            display: inline-flex;
            align-items: center;
            gap: 26px;
            i {
                font-size: $size-17;
                color: var(--gray-400);
            }
            a {
                position: relative;
                font-size: $size-14;
                color: var(--white);
                text-decoration: none;
                font-weight: $medium;
                display: inline-block;
                &::before {
                    content: "|";
                    font-size: $size-14;
                    color: rgba($white, 0.12);
                    font-weight: $medium;
                    left: -15px;
                    position: absolute;
                }
            }
        }
        .display-market-btn {
            @include media-breakpoint-down(sm) {
                padding: 10px 0;
            }
            a {
                display: flex;
                align-items: center;
                gap: 4px;
                i {
                    font-size: $size-16;
                }
                &:hover {
                    background-color: #b09d66;
                }
            }
        }
    }
}

.tab-menu-inner {
    background-color: var(--white);
    margin: 0 !important;
    border-bottom: 1px solid var(--gray-200);
    top: 100px;
    position: sticky;
    z-index: 20;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-down(sm) {
        top: 80px;
    }
    .wrapper-tab {
        display: flex;
        @include media-breakpoint-down(sm) {
            white-space: nowrap;
            overflow-x: auto;
        }
    }
    .nav-item {
        @include media-breakpoint-down(sm) {
            scroll-snap-align: start;
        }
        .nav-link {
            position: relative;
            padding: 20px;
            background-color: transparent;
            color: var(--dark);
            display: inline-flex;
            justify-content: center;
            &::after {
                content: "";
                position: absolute;
                height: 2px;
                background-color: var(--primary);
                bottom: 0;
                opacity: 0;
                display: block;
                width: 10px;
                @include transition-all;
            }
            &.active {
                color: var(--primary);
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fv-team-section {
    position: relative;
    background-color: var(--white);
    padding: var(--space-section) 0;
    .wrapper-team {
        padding-top: var(--space-section);
    }
    .cover_user {
        position: relative;
        img {
            width: 100%;
            height: 450px;
            object-fit: cover;
            @include media-breakpoint-down(sm) {
                height: 250px;
            }
        }
        .display-text-user {
            position: absolute;
            right: 40px;
            bottom: 40px;
            z-index: 2;
            .title {
                font-size: $size-18;
                font-weight: $medium;
                color: var(--white);
                margin-bottom: 8px;
                animation: slide-in 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
            }
            p {
                font-size: 14px;
                color: var(--gray-400);
                margin-bottom: 0;
                animation: slide-in 0.5s cubic-bezier(0.77, 0, 0.18, 1) both 0.1s;
            }
        }
        &::after {
            content: "";
            @include overlay;
            background: linear-gradient(to top, rgba($black, 1), rgba($black, 0));
            @include transition-all;
        }
        &:hover {
            .display-text-user {
                .title {
                    animation: slide-out 0.5s cubic-bezier(0.77, 0, 0.18, 1) both 0.1s;
                }
                p {
                    animation: slide-out 0.5s cubic-bezier(0.77, 0, 0.18, 1) both;
                }
            }
            &::after {
                opacity: 0;
            }
        }
    }
    .arrow-right {
        position: absolute;
        bottom: -33px;
        right: -9px;
        transform: rotate(45deg);
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


.fc-projects-section {
    background-color: var(--white);
    padding: var(--space-section) 0;
    .card-item-project {
        position: relative;
        margin-bottom: 14px;
        border: 0;
        background-color: var(--light);
        .arrow-right {
            position: absolute;
            top: 0;
            right: 0;
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
            border-left: 30px solid var(--white);
            &:first-of-type {
                transform: rotate(-45deg);
                top: -20px;
                right: -6px;
            }
            &:nth-of-type(2) {
                transform: rotate(45deg);
                bottom: -20px;
                right: -6px;
                top: inherit;
            }
        }
        .main-img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            @include media-breakpoint-down(sm) {
                height: 160px;
            }
        }
        .card-body {
            padding: 20px 40px;
            @include media-breakpoint-down(sm) {
                padding: 30px;
            }
            .card-title {
                font-size: $size-20;
                font-weight: $medium;
                color: var(--dark);
                margin-bottom: 16px;
            }
            .card-text {
                font-size: $size-16;
                color: var(--gray-700);
                margin: 0;
            }
        }
    }
    .number_display_black {
        background-color: var(--dark);
        color: var(--white);
        padding: 4px 6px;
        font-size: $size-14;
        font-weight: $medium;
        display: inline-block;
        margin-top: 32px;
    }
}
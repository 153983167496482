@use "../abstracts/variables" as *;

@mixin transition-color {
    transition: color, 0.4s;
}

@mixin transition-bg {
    transition: background, 0.45s;
}

@mixin transition-all {
    transition: 0.45s all;
}


@mixin iconBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: $radius-rounded;
    padding: 0px;
}

@mixin overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}
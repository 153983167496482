@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.fv-agency-section {
    background-color: var(--white);
    padding: var(--space-section) 0 0 0;
    .splideAgency {
        margin-top: var(--space-section);
        position: relative;
        @include media-breakpoint-down(sm) {
            margin-top: 60px;
        }
        &::before {
            content: "";
            background: linear-gradient(to right, rgba($white, 1), rgba($white, 0));
            height: 100%;
            width: 170px;
            position: absolute;
            left: 0;
            z-index: 1;
            top: 0;
            pointer-events: none;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &::after {
            content: "";
            background: linear-gradient(to left, rgba($white, 1), rgba($white, 0));
            height: 100%;
            width: 170px;
            position: absolute;
            right: 0;
            z-index: 1;
            top: 0;
            pointer-events: none;
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .item_agency {
            position: relative;
            width: 350px;
            height: 536px;
            border-left: 1px solid var(--gray-200);
            @include media-breakpoint-down(sm) {
                width: 310px;
            }
            .main_img {
                width: 100%;
                height: 245px;
                object-fit: cover;
            }
            .display-text {
                padding: 32px;
                h4 {
                    font-size: $size-20;
                    font-weight: $medium;
                    margin-bottom: 16px;
                    color: var(--dark);
                }
                p {
                    font-size: $size-14;
                    color: var(--gray-700);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    max-height: 66px;
                }
                .since {
                    font-size: $size-11;
                    color: var(--gray-700);
                    position: absolute;
                    bottom: 70px;
                    right: 32px;
                }
            }
        }
        .splide__arrows {
            position: absolute;
            left: calc(100% /2 - 45px);
            display: flex;
            align-items: center;
            gap: 14px;
            top: -80px;
            @include media-breakpoint-down(sm) {
                top: -34px;
            }
            .splide__arrow--prev {
                opacity: 1;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--white);
                border: 1px solid var(--gray-200);
                border-radius: $radius-circle;
                position: relative;
                right: 0;
                @include transition-all;
                &:hover {
                    background-color: var(--light);
                }
                &::before {
                    content: "\EA54";
                    font-family: remixicon !important;
                    font-size: 20px;
                    color: var(--dark);
                }
                svg {
                    display: none;
                }
            }
            .splide__arrow--next {
                position: relative;
                opacity: 1;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--light);
                border: 1px solid transparent;
                border-radius: $radius-circle;
                left: 0;
                @include transition-all;
                &::before {
                    content: "\EA52";
                    font-family: remixicon !important;
                    font-size: 20px;
                    color: var(--dark);
                }
                svg {
                    display: none;
                }
            }
        }
    }
}

@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

footer {
    @include media-breakpoint-up(lg) {
        margin: 0 -50px;
    }
    padding: 60px 0 0 0;
    background-color: var(--white);
    border-top: 4px solid var(--primary);
    .wrapper-footer {
        .btn-go {
            width: 38px;
            height: 38px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: var(--light);
            color: var(--dark);
            font-size: 20px;
            padding: 0;
            border: 1px solid transparent;
            @include transition-all;
            &:hover {
                background-color: transparent;
                border-color: var(--gray-200);
            }
        }
        .nav {
            padding: 0;
            margin-bottom: 10px;
            gap: 10px;
            .nav-item {
                .nav-link {
                    color: var(--gray-800);
                    font-size: $size-16;
                    font-weight: $medium;
                    padding: 10px 18px;
                    border-radius: $radius-rounded;
                    &.btn-market {
                        background-color: var(--primary);
                        color: var(--white);
                    }
                }
            }
        }
        .fc-copyright {
            font-size: $size-13;
            color: var(--gray-600);
            a {
                font-weight: $medium;
                color: var(--gray-600);
                display: block;
                margin-bottom: 12px;
                &:hover {
                    color: var(--primary);
                }
            }
        }
    }
    .wrapper-social-footer {
        margin-top: 60px;
        background-color: var(--light);
        border-top: 1px solid var(--gray-200);
        .nav {
            display: flex;
            gap: 0;
            align-items: center;
            width: 100%;
            padding: 0;
            margin: 0;
            justify-content: space-between;
            @include media-breakpoint-up(md) {
                flex-wrap: nowrap;
            }
            .nav-link {
                display: inline-flex;
                flex-direction: column;
                gap: 16px;
                justify-content: center;
                text-align: center;
                border-left: 1px solid var(--gray-200);
                width: 100%;
                padding: 24px 10px;
                @include media-breakpoint-down(md) {
                    width: calc(100% / 2);
                    border-bottom: 1px solid var(--gray-200);
                }
                span {
                    font-size: $size-16;
                    color: var(--gray-800);
                    @include transition-color;
                }
                i {
                    font-size: 24px;
                    color: var(--gray-800);
                    @include transition-color;
                }
                &:last-child {
                    border: 0;
                }
                &:hover {
                    i,span {
                        color: var(--primary);
                    }
                }
                &.snapchat  {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
    }
}
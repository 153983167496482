@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

$btn-border-radius: $radius-rounded;
$btn-padding-x: 24px;
$btn-padding-y: 10px;
$btn-font-size: $size-14;
$btn-font-weight: $medium;
.btn {
    --#{$prefix}btn-padding-x: #{$btn-padding-x};
    --#{$prefix}btn-padding-y: #{$btn-padding-y};
    --#{$prefix}btn-font-family: #{$btn-font-family};
    @include rfs($btn-font-size, --#{$prefix}btn-font-size);
    --#{$prefix}btn-font-weight: #{$btn-font-weight};
    --#{$prefix}btn-line-height: #{$btn-line-height};
    --#{$prefix}btn-color: #{$body-color};
    --#{$prefix}btn-border-width: #{$btn-border-width};
    --#{$prefix}btn-border-color: transparent;
    --#{$prefix}btn-border-radius: #{$btn-border-radius};
    --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
    --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
    --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);

    @include transition-all;
  
}
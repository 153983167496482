@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;


@keyframes slide-out {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(30px);
        opacity: 0;
    }
}
@keyframes slide-in {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-toastmsg {
    from {
        transform: translateY(120px);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-out-toastmsg {
    from {
        transform: translateY(0);
        
    }
    to {
        transform: translateY(120px);
    }
}